import { IonButton, IonContent, IonHeader, IonModal } from "@ionic/react"
import { FaWindowClose } from "react-icons/fa"
import { useEffect, useState } from "react"
import { UserState } from "../../Redux/User/Interface"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"

const IndependentUserModal: React.FC<{
    openedId: string | null,
    setOpenedId: Function,
    UserReducer: UserState,
    ApplicationsReducer: ApplicationsState
}> = ({ openedId, setOpenedId, UserReducer, ApplicationsReducer }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [submitType, setSubmittype] = useState(null)
    const [applicationSeats, setApplicaitonSeats] = useState([])
    const [selectedUserAccount, setSelectedUserAccount] = useState(null)

    useEffect(() => {
        if (openedId === null) return
        setApplicaitonSeats(UserReducer.independentUser.seats.filter(seat => seat.account_id === openedId).map((seat) => ({ application_id: seat.application_id, value: seat.seats })))
        setSubmittype(null)
        setSelectedUserAccount(UserReducer.independentUser.accounts.find((account) => account.account_id === openedId))
    }, [openedId])
    
    useEffect(() => {
        if (UserReducer.independentUser.deleteStatus === "fulfilled" && submitType === "delete") {
            setTimeout(() => {setOpenedId(null)}, 500)
        }
    }, [UserReducer.independentUser.deleteStatus])

    const handleBanAccount = () => {
        const data = new FormData()
        data.append("account_id", selectedUserAccount.account_id)
        data.append("session_id", (selectedUserAccount.banned === 1 ? 0 : 1).toString())
        dispatch(UserActions.updateIndependentUsers({ user: data }))
        setSubmittype("ban")
    }

    const handleDeleteAccount = () => {
        dispatch(UserActions.deleteIndependentUsers({ arguments: {account_id : selectedUserAccount.account_id} }))
        setSubmittype("delete")
    }


    const handleAllocatedDevices = () => {
        const data = new FormData()
        const seats = applicationSeats.map((seat) => ({ application_id: seat.application_id, seats: seat.value }))
        data.append("account_id", selectedUserAccount.account_id)
        data.append("available_seats", JSON.stringify(seats))
        dispatch(UserActions.updateIndependentUsers({ user: data }))
        setSubmittype("update")
    }

    if (selectedUserAccount === undefined) return <></>

    return (
        <IonModal key={"delete_User_key"} isOpen={openedId !== null} onWillDismiss={e => { setOpenedId(null) }}>
            <IonHeader>
                <div className="w-full bg-cythero-primary px-2 py-3 flex justify-between items-center">
                    <h1 className="font-bold text-white">Manage Independant user</h1>
                    <FaWindowClose className="text-white text-lg" onClick={e => setOpenedId(null)} />
                </div>
            </IonHeader>
            <IonContent>
                <div className="w-full h-full bg-slate-100 p-4 relative">
                    <div className="flex items-center gap-3">
                        <h1 className="text-slate-800 font-bold">Manage user status:</h1>
                        <IonButton color={"danger"} disabled={UserReducer.independentUser.updateStatus === "pending"} onClick={e => handleBanAccount()}>
                            {selectedUserAccount?.banned === 1 ? "Unban" : "Ban"}
                        </IonButton>
                        <IonButton color={"dark"} disabled={UserReducer.independentUser.updateStatus === "pending"} onClick={e => handleDeleteAccount()}>
                            DELETE
                        </IonButton>
                    </div>

                    {submitType === "ban" && openedId !== null && UserReducer.independentUser.updateStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
                    {submitType === "ban" && openedId !== null && UserReducer.independentUser.updateStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
                    {submitType === "ban" && openedId !== null && UserReducer.independentUser.updateStatus === "fulfilled" ?
                        <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">
                            {selectedUserAccount?.banned === 1 ? "User has been banned." : "User has been unbanned."}
                        </h1> : null
                    }

                    <hr className="my-2 h-1 bg-slate-300" />
                    <h1 className="text-slate-800 font-bold mb-2">Allocate Devices:</h1>
                    <div className="flex flex-col gap-2">
                        {ApplicationsReducer.application.applications.filter(app => app != null && app !== undefined && app.is_demo !== 1).map(app => (
                            <div className="flex items-center gap-3">
                                <h1 className="text-slate-800 font-bold">{app.name}</h1>
                                <input className="bg-white w-[80px] px-2 py-1 text-center bg-slate-200 border border-slate-800 rounded-md" type={"number"} value={applicationSeats.find(seat => seat.application_id === app.id)?.value || 0} onChange={e => {
                                    setApplicaitonSeats([...applicationSeats.filter(seat => seat.application_id !== app.id), { application_id: app.id, value: parseInt(e.target.value) }])
                                }} />
                            </div>
                        ))}
                    </div>
                    <IonButton onClick={e => handleAllocatedDevices()}>UPDATE</IonButton>
                    {submitType === "update" && openedId !== null && UserReducer.independentUser.updateStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
                    {submitType === "update" && openedId !== null && UserReducer.independentUser.updateStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
                    {submitType === "update" && openedId !== null && UserReducer.independentUser.updateStatus === "fulfilled" ?
                        <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">User has been updated</h1>
                        : null}
                </div>
            </IonContent>
        </IonModal>
    )
}

export default IndependentUserModal