import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { useEffect } from "react"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { STATIC_URL } from "../../Common/constants"

const Subscriptions: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()

    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const OrganizationsReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)

    useEffect(() => {
        dispatch(PurchaseActions.readPurchases({ arguments: {} }))
        dispatch(OrganizationActions.readOrganization({ arguments: {} }))
    }, [])

    const uniqueOrderNumbers = new Set();
    const today = new Date()
    const next30Days = new Date(today);
    next30Days.setDate(today.getDate() + 60);

    const purchases = ShopReducer.purchases.filter(p =>
        p !== null &&
        p !== undefined &&
        p.confirmed === 1 &&
        p.product.dlc_type_id === 5 &&
        !uniqueOrderNumbers.has(p.order_number.split("/")[0]) 
        && (() => {
            const renewalDate = new Date(p.purchase_date);
            renewalDate.setDate(renewalDate.getDate() + p.product.subscription_duration);
            if (renewalDate >= today && renewalDate <= next30Days) {
                uniqueOrderNumbers.add(p.order_number.split("/")[0]);
                return true;
            }
            return false;
        })()
    )
    const org_ids = purchases.map(p => p.organization_id)
    return (
        <div>
            <div className="w-full px-6 py-3 bg-cythero-primary flex items-center justify-between mb-3">
                <h1 className="text-white text-3xl">Subscriptions pending renewal in next 60 days</h1>
            </div>
            <div className="flex grid grid-cols-3 gap-3 mx-3">
                {OrganizationsReducer.organization.organizations.filter(org => org !== null && org_ids.includes(org.id)).map(org => (
                    <div>
                        <div className="bg-white rounded-md shadow px-3 py-2">
                            <h1 className="font-bold mb-4 text-slate-800">{org.name}</h1>
                            <div className="grid grid-cols-2 gap-3">
                                {purchases.filter(p => p.organization_id === org.id).map(p => (
                                    <div className="shadow-md rounded flex items-center gap-2">
                                        <img src={STATIC_URL + p.product.cover_path} className="h-12 object-cover" />
                                        <h2 className="font-bold text-slate-700">{p.product.name}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Subscriptions