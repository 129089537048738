import { useIonAlert } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { Applications } from "../../../Common/Interfaces/Entities"
import EditApplicationJSX from "./EditApplicationJSX"
import Loading from "../../../components/Loading"

const EditApplication: React.FC = () => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [infoAlert] = useIonAlert()

    const [application, setApplication] = useState<null | Applications>(null)
    const [submitted, setSubmitted] = useState<boolean>(false)

    useEffect(() => {
        dispatch(ApplicationActions.readPlatforms({ arguments: { "hashed": true } }))
    }, [dispatch])

    useEffect(() => {
        if (submitted === false) return
        if (appsReducer.application.updateStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: 'Application has been updated.',
        })
        if (appsReducer.application.updateStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: appsReducer.application.updateMessage,
        })
    }, [appsReducer.application.updateStatus, appsReducer.application.updateMessage, infoAlert, submitted, dispatch])

    const handleSubmit = () => {
        if (application.name.length < 4) {
            return infoAlert({
                header: 'Invalid name.', buttons: ['OK'],
                message: 'Application name must be longer than 4 characters.',
            })
        } else if (application.description.length < 64) {
            return infoAlert({
                header: 'Invalid description.', buttons: ['OK'],
                message: 'Application description must be longer than 64 characters.',
            })
        } else if (/^#[0-9A-F]{6}$/i.test(application.primary_color) === false) {
            return infoAlert({
                header: 'Invalid primary color.', buttons: ['OK'],
                message: 'Application primary color must be hexadecimal color code.',
            })
        } else if (application.cover_path === null) {
            return infoAlert({
                header: 'Missing cover photo.', buttons: ['OK'],
                message: 'Application must have a cover photo.',
            })
        } else if (application.logo_path === null) {
            return infoAlert({
                header: 'Missing logo.', buttons: ['OK'],
                message: 'Application must have a logo.',
            })
        } else if (application.price === null || isNaN(application.price)) {
            return infoAlert({
                header: 'Price issue.', buttons: ['OK'],
                message: 'Price must be a numerical value.',
            })
        }

        setSubmitted(true)
        const body = new FormData()
        body.append("id", application.id.toString())
        body.append("name", application.name)
        body.append("description", application.description)
        body.append("primary_color", application.primary_color)
        body.append("platforms", JSON.stringify(application.platforms))
        body.append("logo", application.logo_path)
        body.append("cover", application.cover_path)
        body.append("price", application.price.toString())
        body.append("is_demo", application.is_demo.toString())

        dispatch(ApplicationActions.updateApplication({ application: body }))
    }

    if (appsReducer.platforms.readStatus === "pending") return <Loading isOpen={true} message={appsReducer.platforms.readMessage} />
    if (appsReducer.application.readStatus === "pending") return <Loading isOpen={true} message={appsReducer.application.readMessage} />
    if (appsReducer.application.updateStatus === "pending") return <Loading isOpen={true} message={appsReducer.application.updateMessage} />
    const app = appsReducer.application.applications.find(a => a !== undefined && a !== null &&  a.id === id)
    if (appsReducer.application.readStatus === "fulfilled" && app === undefined) return <h1>Cannot find this application</h1>

    if (appsReducer.platforms.readStatus === "fulfilled" && application === null) setApplication({ ...app })

    return application === null ? null : <EditApplicationJSX
        application={application}
        setApplication={setApplication}
        platforms={appsReducer.platforms.platforms.filter(p => p !== null && p !== undefined)}
        handleSubmit={handleSubmit}
    />
}

export default EditApplication