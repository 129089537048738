import Application from "../ApplicationCard/Application"
import { MdAddToPhotos } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { IonButton, IonFab } from "@ionic/react"

const ApplicationsList = ({ applications, user }) => (
    <div className="flex flex-col flex-wrap lg:flex-row gap-8 w-11/12 mx-auto items-center pb-12">
        {applications.filter(app => app !== null && app !== undefined && (user.type_id === 4 || app.is_demo !== 1)).map(application =>  <Application key={application.id} application={application} />)}
        {user.type_id === 4 ? <IonFab vertical="bottom" horizontal="end" >
            <IonButton>
                <NavLink to="/applications/create"><MdAddToPhotos className="text-3xl" /></NavLink>
            </IonButton>
        </IonFab> : null}
    </div>
)

export default ApplicationsList