import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { useEffect, useState } from "react"
import { LanguageActions } from "../../Redux/Languages/Actions"
import { LanguagesState } from "../../Redux/Languages/Interface"
import Input from "../../components/Form/Input"
import { IonButton } from "@ionic/react"

const groups = {
    "Navigation": ["Dashboard", "Reports", "Settings", "Languages", "Organization", "Applications", "Shopping Cart", "Purchases", "Logout"],
    "Settings": ["Organization specific settings ", "Application", "Parameter", "Value", "Action"],
    "Organization Screen": ["Managed By", "Organization Users", "Search User(s)", "ADD NEW", "Show More", "Show Less", "Leave Organization", "Edit User", "General Information", "Enter organization name", "Enter organization email", "Enter Product Key", "Select organization logo", "Shipping Information", "Select country of residence", "Enter city of residence", "Enter zip code", "Enter street name and house/apartment number", "Enter phone number to contact", "Enter email to contact", "Enter first and last name of person to contact", "Update Organization", "Update Address", "Purchased Applications", "Purchased on"],
    "Manage Application Screen": ["Supported On", "Branding Profiles", "Search for profile", "Application Purchases", "Application Devices", "Are you sure you want to delete this device", "User's access to applicaiton", "Grant Access To Application", "GRAND ACCESS", "Are you sure you want to delete this user's access from this application?", "Your Settings"],
    "Analytics Screen": ["Reports", "Select Report Type", "Trainee Performance Report", "Part Report", "Training Sessions"],
    "SprayVerse Dashboard": ["SprayVerse Dashboard Report", "OVERALL", "WEEK", "MONTH", "YTD", "EXPORT", "Primer Coat Price", "Material Used", "Price per Liter", "Savings", "Base Coat Price", "Material Used", "Price per Liter", "Savings", "Clear Coat Price", "Material Used", "Price per Liter", "Savings", "Total", "Material Used", "Savings", "Not enough data to display this report. Try to select a wider range of dates.", "Time Spent (min)", "User activity over time on SprayVerse", "Time Spent (min)", "Search user", "User", "Total Time Spent", "Avg. Material Used (ml) / Target", "Avg. Coverage (%) / Target", "Average Grade", "Fender", "Hood", "Roof Tile", "Door", "Tail Wing", "Wing", "Propeller", "Beam", "Rail Section", "Rear Bumper", "Front Bumper", "Sedan Trunk", "Side Mirrors", "Fan Cowling", "Nose Cone", "Small Pipe", "90 Degree Elbow", "Large Pipe", "Equal Tee Joint", "Cabinet", "Window", "House Door", "Window Shutter", "Training Platform", "Full Car", "Small Plane"],
    "SprayVerse Session Browser": ["Date", "Paint Time", "Part", "Grade", "Close"],
    "SprayVerse Session Card": ["Session Info", "Close", "painted", "Coverage Graph", "Engagement Graph", "Primer Coat Info", "Base Coat Info", "Clear Coat Info", "Overall Info", "Time Spent (min)", "Material Used", "Grade", "Low Coverage", "Good Coverage", "High Coverage", "EXPORT TO PDF"],
    "SprayVerse Reports": ["Report Filters", "Select Users", "Select Date Range", "GENERATE REPORT", "PRINT ALL", "General Information", "User", "Times Played", "Most Painted Part", "Total Training Time", "Grades Brakdown", "Part", "Grade", "Coverage", "Paint Used", "Time Taken", "Coverage Graph (Last 10 sessions)", "Not enough data to display this report. Try to select a wider range of dates.", "Part Report", "OVERALL", "Primer", "Base", "Clear", "Average Grade", "Coverage Breakdown", "EXPORT TO PDF", "Select Part", "High", "Good", "Low", "No Coverage", "Date", "Paint Used (ml)", "Total Time Spent (min)"],
    "WeldVR Dashboard": ["Training Methods Distribution", "MIG", "TIG", "STICK", "Training Parts Distribution", "Butt Joint", "Lap Joint", "Tee Joint", "Edge Joint", "Corner Joint", "General Information", "Number of sessions", "Avg. Work Angle", "Avg. Travel Angle", "Avg. Speed", "Avg. Distance", "Most Used Method", "Most Welded Part", "Welding Time", "Method"],
    "WeldVR Session Card": ["Work Angle", "Travel Angle", "Distance", "Speed", "Welded", "Session Duration"],
    "WeldVR Reports": ["Select Welding Methods"],
    "Overall Dashboard": ["General Dashboard Report", "User activity over time on all applications", "Most active users", "Activity Timeline", "Most Played Applications", "Total Time Painted"],
    "User Management": ["Create a new user", "Edit User Profile", "Select user type", "Select Application Access", "Enter first name", "Enter last name", "Enter email", "Enter username", "Enter password", "Confirm password", "Generate a pin", "User Pin", "Enter 4 digit pin (optional)", "GENERATE NEW", "CLEAR", "CREATE USER", "UPDATE USER"],
    "Purchases Module": ["Search", "View Details", "ADD TO CART", "REMOVE FROM CART", "You own this product", "No items in the cart.", "Purchase Receipt", "Total Amount", "DELETE", "CHECKOUT", "Purchase History", "Customer", "Customer Type", "Purchase Date", "Order Number", "Price", "Purchase Information", "Purchased Packages", "Purchased Products", "Status", "Confirmed", "Pending"]
}

const LanguagesMenagement: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const LanguagesReducer: LanguagesState = useSelector((state: RootState) => state.language)
    const [selectedLanguage, setSelectedLanguage] = useState(1)
    const [data, setData] = useState(Object.keys(LanguagesReducer.translations.translations).map(label => ({
        label,
        translated: LanguagesReducer.translations.translations[label][selectedLanguage]
    })))
    const [selectedGroup, setSelectedGroup] = useState("Navigation")

    useEffect(() => { dispatch(LanguageActions.readLanguage({ arguments: {} })) }, [])
    useEffect(() => {
        let set = []
        for (const key of groups[selectedGroup]) {
            console.log(key)
            set.push({
                label: key,
                translated: LanguagesReducer.translations.translations[key][selectedLanguage]
            })
        }
        setData(set)
    }, [selectedLanguage, selectedGroup])

    const handleChange = e => {
        setData([
            ...data.map(item => item.label !== e.target.name ? ({ ...item }) : ({
                label: item.label,
                translated: e.target.value
            }))
        ])
    }

    const handleSubmit = e => {
        e.preventDefault()
        const _data = new FormData()
        _data.append("language_id", selectedLanguage.toString())
        _data.append("translations", JSON.stringify(data.map(item => ({ english: item.label, translated: item.translated }))))
        dispatch(LanguageActions.createTranslation({ translations: _data }))
    }

    return (
        <div>
            <div className="w-full bg-slate-900 px-2 py-1 flex gap-4">
                {LanguagesReducer?.languages?.languages?.map(lang => lang === null || lang === undefined ? null : <p className={(selectedLanguage === lang.id ? "underline" : "") + " text-white text-lg cursor-pointer"} onClick={e => setSelectedLanguage(lang.id)}>
                    {lang.name}
                </p>)}
            </div>
            <div className="bg-slate-700 p-2">
                {Object.keys(groups).map(key => <IonButton color={selectedGroup === key ? "primary" : "light"} onClick={e => setSelectedGroup(key)}>{key}</IonButton>)}
            </div>
            <div className="w-[900px] max-w-[95%] mx-auto my-12 bg-white rounded-md shadow-md p-2">
                <p className="text-slate-700 m-4 text-lg">Manage <b>{selectedGroup}</b> translations</p>
                {data.map(item => <div className="">
                    <Input
                        name={item.label}
                        handleChange={e => handleChange(e)}
                        type="text"
                        value={item.translated === item.label ? "" : item.translated}
                        placeholder={item.label}
                        accept="*"
                    />
                </div>)}
                <IonButton className="block mx-auto" onClick={e => handleSubmit(e)}>SUBMIT</IonButton>
            </div>
        </div>
    )
}

export default LanguagesMenagement