import { MdLanguage } from "react-icons/md";
import { UserState } from "../../../Redux/User/Interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/Store";
import { LanguagesState } from "../../../Redux/Languages/Interface";
import { useEffect, useState } from "react";
import { UserActions } from "../../../Redux/User/Actions";

const LanguageSelector: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const LanguageReducer: LanguagesState = useSelector((state: RootState) => state.language)
    const [selected, setSelected] = useState(UserReducer.current.language_id)

    useEffect(() => {
        if (selected === UserReducer.current.language_id) return
        setTimeout(() => {
            const data = new FormData()
            data.append("id", UserReducer.current.id.toString())
            data.append("language_id", selected.toString())
            dispatch(UserActions.updateUser({ user: data }))
        }, 300)
    }, [selected])

    const languages = LanguageReducer.languages.languages.filter(language => language !== null)

    return (
        <div className="flex items-center gap-2 relative">
            <MdLanguage className="text-lg  text-white" />
            <select className="absolute w-full outline-none pl-6 bg-[#00000000] text-white" onChange={e => setSelected(Number(e.target.value))} value={selected}>
                {languages.map((language, i) => (
                    <option className="overflow-hidden text-slate-800 w-full bg-white" key={i} value={language.id}>{language.name}</option>
                ))}
            </select>
        </div>
    )
}

export default LanguageSelector