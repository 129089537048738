import { createSlice } from "@reduxjs/toolkit";
import { UserReducers } from "./Actions"
import { UserState } from "./Interface";


const init: UserState = {
    current: null,
    registerStatus: "idle",
    registerMessage: "",
    loginStatus: "idle",
    loginMessage: "",
    createUserStatus: "idle",
    createUserMessage: "",
    readUserStatus: "idle",
    readUserMessage: "",
    updateUserStatus: "idle",
    updateUserLanguageMessage: "",
    updateUserLanguageStatus: "idle",
    updateUserMessage: "",
    deleteUserStatus: "idle",
    deleteUserMessage: "",
    users: [],
    token: "",
    refresh: "",
    userTypes: {
        readStatus: "idle",
        readMessage: "",
        types: []
    },
    check_auth: true,
    address: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        addresses: []
    },
    countries: {
        readStatus: "idle",
        readMessage: "",
        countries: []
    },
    resetPassword: {
        emailStatus: "idle",
        emailMessage: "",
        
        verifyStatus: "idle",
        verifyMessage: "",

        resetStatus: "idle",
        resetMessage: "",
        userEmail: ""
    },
    demoUsers: {
        users: [],
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
    },
    independentUser: {
        sessions: [],
        accounts: [],
        seats: [],
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
    }
}

const UserSlice = createSlice({
    name: "user",
    initialState: init,
    reducers: {
        logout: state => {
            state.current = null
            state.token = ""
            state.refresh = ""
        },
        setUserState: (state: UserState, action) => {
            for (let prop in action.payload) {
                state[prop] = action.payload[prop]
            }
            return state
        },
        toggleAuthCheck: (state: UserState) => {
            state.check_auth = !state.check_auth
            return state
        },
        setUserOrganization: (state: UserState, action) => {
            if (state.current.type_id !== 4) {
                state.current.organization_id = action.payload
                state.current.type_id = 3
                state.users[state.current.id].organization_id = action.payload
                state.users[state.current.id].type_id = 3
            }
            return state
        }
    },
    extraReducers(builder) {
        for (const action in UserReducers) {
            builder.addCase(action, UserReducers[action])
        }
    }
})


export const { logout, setUserState, toggleAuthCheck, setUserOrganization } = UserSlice.actions
export default UserSlice.reducer