import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import Select from "../../../../components/Form/Select"
import File from "../../../../components/Form/FileUpload"
import { PurchasesState } from "../../../../Redux/Purchases/Interface"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"


import * as XLSX from 'xlsx';
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import Translate from "../../../../components/Translate"
import { MdAddBox } from "react-icons/md"
import { NavLink, useHistory } from "react-router-dom"
import { UserActions } from "../../../../Redux/User/Actions"
import { UserState } from "../../../../Redux/User/Interface"

function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const BulkCreateModal = ({ organization }) => {
    const dispatch = useDispatch<AppDispatch>()

    const history = useHistory()

    const [modal, setModal] = useState(false)
    const [selectedApps, setSelectedApps] = useState<number[]>([])
    const [csvData, setCsvData] = useState([])
    const [step, setStep] = useState(0)
    const [submitted, setSubmitted] = useState(false)
    const PurchaseReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const purchased_apps = PurchaseReducer.purchases.filter(p => p !== null && p !== undefined && p.organization_id === organization.id && p.confirmed === 1 && p.application_id !== null)
    const UsersReducer: UserState = useSelector((state: RootState) => state.user)

    const handleFileUpload = (event) => {
        const file = event.target.firstChild.files[0];
        const reader = new FileReader()

        reader.onload = (e) => {
            const data = e.target.result
            const workbook = XLSX.read(data, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 })

            const [email, firstName, lastName, username] = [0, 1, 2, 3]

            const parsedRows = parsedData.slice(1).map(row => ({
                email: row[email],
                firstName: row[firstName],
                lastName: row[lastName],
                username: row[username],
                password: generateRandomString(8),
                apps_access: selectedApps
            }))
            setCsvData(parsedRows)
        }
        if (file) reader.readAsBinaryString(file)
    }

    const handleSubmit = () => {
        const data = new FormData()
        data.append('users', JSON.stringify(csvData))
        data.append('organization_id', organization.id.toString())

        dispatch(UserActions.createUsers({ users: data }))
        setSubmitted(true)
    }

    const handleUseWizzard = () => {
        setModal(false)
        setStep(0)
        setSelectedApps([])
        setCsvData([])
        setSubmitted(false)
        setTimeout(() => {
            history.push(`/users/bulk_create/` + organization.id)
        }, 100)
    }

    useEffect(() => {
        setStep(0)
        setSelectedApps([])
        setCsvData([])
        setSubmitted(false)
    }, [modal])

    return (
        <div>
            <button onClick={e => setModal(true)} className="text-sm rounded hover:shadow-md bg-cythero-primary text-white cursor-pointer flex items-center gap-2 p-1.5 font-bold"><Translate label="ADD BULK" /> <MdAddBox className="text-lg" /></button>
            <IonModal trigger="open-modal" isOpen={modal} onDidDismiss={e => setModal(false)}>
                <IonContent>
                    <IonToolbar color={"primary"}>
                        <IonTitle><Translate label="BULK ADD USERS" /></IonTitle>
                        <IonButtons slot="end">
                            <IonButton color="light" onClick={() => setModal(false)}>
                                CLOSE
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <div className="p-4">
                        {step === 0 ? (
                            <div className="flex flex-col gap-2 items-center justify-center h-[350px]">
                                <p className="font-bold text-slate-800 text-center">
                                    <Translate label="Would you like to upload a CSV, or use the bulk create wizzard?" />
                                </p>
                                <div className="flex items-center gap-2 justify-center">
                                    <IonButton color={"primary"} onClick={e => setStep(1)}>
                                        <Translate label="Upload CSV" />
                                    </IonButton>
                                    <IonButton onClick={e => handleUseWizzard()}>
                                        <Translate label="Use Wizzard" />
                                    </IonButton>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p className="text-sm text-slate-600">
                                    <Translate label="Please download the template found " />
                                    <a className="text-cythero-primary underline font-bold" href="https://docs.google.com/spreadsheets/d/1QP9QqZZUdINydEf3UEb8pcFemAPZxJqccOrBL3vLpi0/edit?usp=sharing" target="_blank">
                                        <Translate label="here" />
                                    </a>
                                    <br />
                                    <Translate label="Make sure to export the file you save as a .CSV file " />
                                    <br />
                                    <br />
                                    <Translate label="Access codes will be automatically generated by the system and provided to the users via email." />
                                </p>
                                <br />
                                <Select
                                    displayField="name"
                                    valueField="id"
                                    options={purchased_apps.map(p => ({
                                        id: p.application_id,
                                        name: ApplicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === p.application_id)?.name
                                    }))}
                                    value={selectedApps}
                                    handleChange={e => {
                                        setSelectedApps(e.target.value)
                                        setSubmitted(false)
                                    }}
                                    multiple={true}
                                    placeholder="Select Apps you want the users to access"
                                    name="apps_access"
                                />
                                <File
                                    type="file"
                                    name="users"
                                    handleChange={e => {
                                        handleFileUpload(e)
                                        setSubmitted(false)
                                    }}
                                    placeholder="Upload Users"
                                    value={csvData === null ? null : csvData}
                                />
                                {submitted === true ? <div>
                                    {UsersReducer.createUserStatus === "pending" ? <Translate label="Loading..." /> :
                                        UsersReducer.createUserStatus === "fulfilled" ? <Translate label="Users created successfully" /> :
                                            UsersReducer.createUserStatus === "rejected" ? <Translate label="Error creating users" /> :
                                                null}
                                </div> : <IonButton className="mt-5 block mx-auto w-1/3" onClick={e => handleSubmit()}>Submit</IonButton>}
                            </>
                        )}
                    </div>
                </IonContent>
            </IonModal>
        </div>
    )
};

export default BulkCreateModal