
import { useEffect, useState } from "react"
import { BsFillCaretLeftFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { STATIC_URL } from "../../Common/constants"
import UserOwnsAnalytics from "../../components/UserOwnsAnalytics/UserOwnsAnalytics"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import ReportTypes from "./Reports/ReportTypes"
import Translate from "../../components/Translate"
import Loading from "../../components/Loading"

const AnalyticsContainer: React.FC = () => {
    const app_id: number = parseInt(useParams<{ id: string }>().id)

    const dispatch = useDispatch<AppDispatch>()
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    const [selectedReport, setSelectedReport] = useState(null)

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ arguments: {organization_id : UserReducer.current.organization_id } }))
    }, [])

    useEffect(() => {
        setSelectedReport(null)
    }, [app_id])
    
    if (
        ShopReducer.readStatus === "pending" ||
        AppsReducer.application.readStatus === "pending"
    ) return <Loading isOpen={true} message="Loading reports data... Please wait." />


    const Application = AppsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === app_id)

    if (Application === undefined) return <h1 className="w-full bg-red-500 text-center text-white text-2xl font-bold py-12 px-2">Application Not Found</h1>

    return (
        <UserOwnsAnalytics
            application={Application}
            purchases={ShopReducer.purchases}
            user={UserReducer.current}
        >
            <div className="w-full">
                {selectedReport === null ? <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center my-4 w-full">
                        <img src={STATIC_URL + Application.logo_path} className="w-10 md:w-16 mr-1 md:mr-4" alt="app logo" />
                        <h1 className="text-2xl md:text-4xl font-bold text-slate-700">{Application.name} <Translate label={"Reports"} /></h1>
                    </div>
                    <h1 className="text-2xl text-slate-800"><Translate label={"Select Report Type"} /></h1>
                    <div className="flex flex-col md:flex-row items-center justify-center">
                        {ReportTypes[Application.id]?.map(r =>
                            <div
                                className="
                                    rounded cursor-pointer bg-cythero-primary text-white mt-4 flex items-center
                                    mx-1 w-full p-3 flex-row items-start md:mx-4 md:w-[200px] md:h-[200px] md:flex-col md:justify-center
                                "
                                key={r.name}
                                onClick={e => setSelectedReport(r)}
                            >
                                <p className="text-3xl md:text-8xl mb-2">{r.icon}</p>
                                <p className="text-md md:text-lg text-center px-1"><Translate label={r.name} /></p>
                            </div>
                        )}
                    </div>
                </div> : <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center my-4 w-full">
                        <img src={STATIC_URL + Application.logo_path} className="w-10 md:w-16 mr-1 md:mr-4" alt="app logo" />
                        <h1 className="text-2xl md:text-4xl font-bold text-slate-700">{Application.name} <Translate label={"Reports"} /></h1>
                    </div>
                    <h1 className="hidden md:block text-2xl text-slate-800"><Translate label={"Report Filters"} /></h1>
                    <div>
                        <span
                            onClick={e => setSelectedReport(null)}
                            className="cursor-pointer rounded flex items-center justify-center text-white bg-slate-500 w-20 h-8 gap-2 ml-1 md:ml-4"
                        >
                            <BsFillCaretLeftFill /> BACK
                        </span>
                        <div className="flex mt-4 items-start flex-col md:flex-row min-w-[300px] max-w-[300px] md:max-w-[800px]">
                            <div
                                className="
                                z-10
                                rounded-t  md:rounded cursor-pointer bg-cythero-primary text-white mt-4 md:mt-0 flex items-center
                                w-full p-3 flex-row md:mx-4 md:w-[200px] md:h-[200px] md:flex-col md:justify-center
                                "
                            >
                                <p className="text-2xl md:text-8xl md:mb-2 mr-2 md:mr-0">{selectedReport.icon}</p>
                                <p className="text-lg text-center px-1"><Translate label={selectedReport.name} /></p>
                            </div>
                            <div className="w-full -mt-1 md:mt-0 md:w-[600px]">{selectedReport.filterComponent}</div>
                        </div>
                    </div>
                </div>}
                {selectedReport === null ? null : <div className="w-full p-5 mt-48 md:mt-20"> {selectedReport.reportComponent} </div>}
            </div>
        </UserOwnsAnalytics>
    )
}

export default AnalyticsContainer