
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router"
import { getUserPurchases } from "../../Common/Functions"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import DashboardContainerJSX from "./DashboardContainerJSX"
import ApplicationSelect from "./ApplicationSelect/ApplicationSelect"
import Loading from "../../components/Loading"


const DashboardContainer: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const route = useLocation().pathname
    const id = useParams<{id:any}>().id
    const user: UserState = useSelector((state: RootState) => state.user)
    const appsReducer = useSelector((state: RootState) => state.apps)
    const purchaseReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const [requested, setRequested] = useState(false)

    useEffect(() => {
        if (isNaN(parseInt(route)) === false || requested === true) return
        dispatch(ApplicationActions.readApplication({}))
        dispatch(PurchaseActions.readPurchases({ arguments: { organization_id : user.current.organization_id }}))
        setRequested(true)
    }, [dispatch, route, requested])

    const user_apps = appsReducer.application.applications.filter(a => a !== null && a !== undefined && a.is_demo !== 1 && getUserPurchases(purchaseReducer.purchases, user.current)["application"].includes(a.id))

    if (
        appsReducer.application.readStatus !== "fulfilled" ||
        purchaseReducer.readStatus !== "fulfilled" || 
        requested === false
    ) return <Loading isOpen={true} message="Loading application data. Please wait." />
    return (
        <DashboardContainerJSX route={route}>
            <ApplicationSelect id={id} user_apps={user_apps} route={route} current_user={user.current} />
        </DashboardContainerJSX>
    )
}

export default DashboardContainer