import { RootState, store } from "../Redux/Store"
import { Purchase, User } from "./Interfaces/Entities"
export const validateEmail = email => String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)


export const insertInHash = (arr, ele, key, obh = false) => {
    let a = [...arr]
    if (obh === true) {
        if (a[ele[key]] === null || a[ele[key]] === undefined) a[ele[key]] = []
        a[ele[key]].push(ele)
    }
    else a[ele[key]] = ele
    return a
}

export const getTranslation = label => {
    let state:RootState = store.getState()
    try{
        return state.language.translations.translations[label][state.user.current.language_id] || state.language.translations.translations[label][2]
    } catch(e) {
        return label
    }
}

export const removeFromHash = (arr, ele = null, key = null) => {
    let a = [...arr]
    if (ele === null) a[key] = null
    else a[ele[key]] = ele
    return a
}


export const getUserPurchases = (_purchases: Array<Purchase>, user: User) => {
    const purchases = {
        dlc: [],
        license: [],
        application: [],
        branding_dlc: []
    }
    
    if (user === null) return purchases
    
    for (const p of _purchases) {
        if (p === null || p === undefined) continue
        if (p.confirmed === 0) continue
        if (user.organization_id !== null && p.organization_id !== user.organization_id) continue
        if (user.organization_id === null && p.user_id !== user.id) continue

        if (p.dlc_id !== null) {
            if (p.product.type === "branding_dlc") purchases.branding_dlc.push(p.dlc_id)
            else purchases.dlc.push(p.dlc_id)
        }
        if (p.license_id !== null) purchases.license.push(p.license_id)
        if (p.application_id !== null) purchases.application.push(p.application_id)
    }

    return purchases
}

export const userOwnsProduct = (purchases: Array<Purchase>, user: User, type: "application" | "dlc" | "branding", product_id: number) => {
    for (const p of purchases) {
        if (p === null || p === undefined) continue
        if (p.confirmed === 0) continue
        if (p.product.id === product_id && p.product.type === type) {
            if ((user.organization_id === null && p.user_id === user.id) || (p.organization_id === user.organization_id)) {
                if (type === "dlc" && p.product.dlc_type_id === 5) {
                    const time_diff = new Date().getTime() - new Date(p.purchase_date).getTime()
                    const day_diff = time_diff / (1000 * 3600 * 24)
                    if (Math.round(day_diff) <= p.product.subscription_duration) return p
                    else continue
                }
                return p
            }
        }
    }
    return false
}