import { FaUserAlt } from "react-icons/fa"
import { GiCarDoor } from "react-icons/gi"
import { TbBrandGoogleAnalytics } from "react-icons/tb"
import PartReport from "./Sprayverse/PartReport"
import UsageReport from "./Sprayverse/UsageReport"
import UserReport from "./Sprayverse/UserReport"
import PartReportFilters from "./Sprayverse/Filters/PartReportFilters"
import UsageReportFilters from "./Sprayverse/Filters/UsageReportFilters"
import UserReportFilter from "./Sprayverse/Filters/UserReportFilter"
import WeldingUsageReportFilters from "./Welding/Filters/WeldingUsageReportFilters"
import WeldingUsageReport from "./Welding/WeldingUsageReport"
import WeldingUserReportFilter from "./Welding/Filters/WeldingUserReportFilter"
import WeldingUserReport from "./Welding/WeldingUserReport"
import WeldingPartReportFilters from "./Welding/Filters/WeldingPartReportFilters"
import WeldingPartReport from "./Welding/WeldingPartReport"

const ReportTypes = {
    1: [
        {
            name: "Trainee Performance Report", icon: <FaUserAlt />,
            filterComponent: <UserReportFilter />, reportComponent: <UserReport />
        },
        {
            name: "Part Report", icon: <GiCarDoor />,
            filterComponent: <PartReportFilters />, reportComponent: <PartReport />
        },
        {
            name: "Training Sessions", icon: <TbBrandGoogleAnalytics />,
            filterComponent: <UsageReportFilters />, reportComponent: <UsageReport />
        }
    ], 
    7: [
        {
            name: "Trainee Performance Report", icon: <FaUserAlt />,
            filterComponent: <WeldingUserReportFilter />, reportComponent: <WeldingUserReport />
        },
        {
            name: "Part Report", icon: <GiCarDoor />,
            filterComponent: <WeldingPartReportFilters />, reportComponent: <WeldingPartReport />
        },
        {
            name: "Training Sessions", icon: <TbBrandGoogleAnalytics />,
            filterComponent: <WeldingUsageReportFilters />, reportComponent: <WeldingUsageReport />
        }
    ],
    9: [],
    10: [],
    11: []
}

export default ReportTypes