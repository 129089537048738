import Input from "../../../components/Form/Input";
import { IonButton } from "@ionic/react";
import Loading from "../../../components/Loading";
import { getTranslation } from "../../../Common/Functions";
import Translate from "../../../components/Translate";
import Select from "../../../components/Form/Select";


const EditOrganizationFormJSX = ({
    organizationReducer,
    org,
    setOrg,
    selectedLanguage,
    setSelectedLanguage,
    languages,
    handleSubmit
}) => (
    <div className="p-8 bg-white rounded-lg shadow mb-4">
        <Loading isOpen={organizationReducer.organization.updateStatus === "pending"} message="Updating organization... Please wait." />
        <h3 className="text-slate-700 font-medium text-lg"><Translate label="General Information" /></h3>
        <Input
            type="text"
            name="name"
            value={org.name}
            placeholder={getTranslation("Enter organization name")}
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            accept=""
        />
        <Input
            type="email"
            name="email"
            value={org.email}
            placeholder={getTranslation("Enter organization email")}
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            accept=""
        />
        <Input
            type="text"
            name="ocolus_id"
            value={org.ocolus_id}
            placeholder={getTranslation("Enter Product Key")}
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            accept=""
        />
        <Input
            accept="image/*"
            name="profile_picture"
            value={org.profile_picture}
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.files[0] })}
            type="file"
            placeholder={getTranslation("Select organization logo")}
        />
        <Select 
            displayField="name"
            valueField="id"
            value={selectedLanguage}
            options={languages.filter(lang => lang !== null)}
            handleChange={e => setSelectedLanguage(e.target.value)}
            placeholder={getTranslation("Select language")}
            multiple={false}
        />
        <IonButton onClick={handleSubmit} className="mt-8 ml-3"><Translate label="Update Organization" /></IonButton>
    </div>
)

export default EditOrganizationFormJSX