import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { useEffect, useState } from "react"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import Loading from "../../components/Loading"
import { setUserState } from "../../Redux/User/Slice"
import { DataGrid } from '@mui/x-data-grid'
import { darken, lighten, styled } from '@mui/material/styles';
import IndependentUserModal from "./IndependentUserModal"

const getBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
const getHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
const getSelectedHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Banned': {
    backgroundColor: getBackgroundColor(
      theme.palette.grey[500],
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const IndependentUsers: React.FC<{}> = ({ }) => {
  const dispatch = useDispatch<AppDispatch>()
  const UserReducer: UserState = useSelector((state: RootState) => state.user)
  const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
  const [openedId, setOpenedId] = useState(null)

  useEffect(() => {
    dispatch(UserActions.readIndependentUsers({ arguments: {} }))
    dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
  }, [])

  useEffect(() => {
    if (UserReducer?.independentUser?.updateStatus === undefined) return
    if (UserReducer.independentUser.updateStatus === "fulfilled") {
      setTimeout(() => {
        setOpenedId(null)
        dispatch(setUserState({
          ...UserReducer,
          independentUser: {
            ...UserReducer.independentUser,
            updateStatus: "idle"
          }
        }))
      }, 1500)
    } else if (UserReducer.independentUser.updateStatus === "rejected") {
      setTimeout(() => {
        dispatch(setUserState({
          ...UserReducer,
          independentUser: {
            ...UserReducer.independentUser,
            updateStatus: "idle"
          }
        }))
        setOpenedId(null)
      }, 1500)
    }
  }, [UserReducer?.independentUser?.updateStatus])

  if (UserReducer?.independentUser?.updateStatus === undefined) return <Loading isOpen={true} message="Loading data... Please wait." />
  if (ApplicationsReducer.application.readStatus === "pending" || UserReducer.independentUser.readStatus === "pending") return <Loading isOpen={true} message="Loading data... Please wait." />

  const columns = [
    { field: 'id', headerName: 'ID', width: 40, hide: true },
    { field: 'device_id', headerName: 'Device ID', width: 400 },
    { field: 'account_id', headerName: 'Account Name', width: 200 },
    { field: 'application_id', headerName: 'Application', width: 120 },
    { field: 'date_registered', headerName: 'Register Date', type: 'date', width: 100 },
    { field: 'ip_address', headerName: 'IP', width: 140 },
    { field: 'location', headerName: 'Location', width: 180 },
    { field: 'last_session', headerName: 'Last Play', width: 140, type: 'date' },
    { field: 'status', headerName: 'Status', width: 100 },
  ];

  const rows = UserReducer.independentUser?.sessions?.map(user => {
    const account = UserReducer.independentUser?.accounts.find(account => account.account_id === user.account_id)
    if (account === undefined) return null
    return {
      id: user.id,
      device_id: user.device_id,
      account_id: user.account_id,
      application_id: ApplicationsReducer.application.applications.find(app => app !== null && app !== undefined && app.id === user.application_id).name,
      date_registered: new Date(user.date),
      ip_address: user.ip_address,
      location: user.location,
      last_session: new Date(user.last_session),
      status: account.banned === 1 ? "Banned" : "Active",
    }
  }) || []

  function copyToClipboard(text) {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);

    tempInput.value = text;
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  return (
    <div className="w-full mx-auto">
      <div className="w-[1440px] max-w-[95%] mx-auto bg-white mb-12 mt-12">
        <span className="flex justify-between items-center px-3 py-1 bg-slate-700">
          <p className="text-white font-bold uppercase text-xl">List of Independent users</p>
        </span>
        <StyledDataGrid
          rows={rows.filter(r => r !== null)}
          columns={columns}
          disableRowSelectionOnClick={true}
          isRowSelectable={() => true}
          getRowClassName={(params) => {
            return `super-app-theme--${params.row.status === "Banned" ? "Banned" : ""}`
          }}
          onRowDoubleClick={(e) => setOpenedId(e.row.account_id)}
          onCellClick={e => copyToClipboard(e.formattedValue)}
        />
      </div>
      <IndependentUserModal
        UserReducer={UserReducer}
        ApplicationsReducer={ApplicationsReducer}
        openedId={openedId}
        setOpenedId={setOpenedId}
      />
    </div>
  )
}

export default IndependentUsers