
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { userOwnsProduct } from "../../../Common/Functions"
import { ApplicationPackages, Applications, ApplicationVersions, DLC, DlcTypes, VersionTypes } from "../../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { PurchaseActions } from "../../../Redux/Purchases/Actions"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserState } from "../../../Redux/User/Interface"
import ApplicationScreenJSX from "./ApplicationScreenJSX"
import Loading from "../../../components/Loading"

const ApplicationScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()

    const appId: number = parseInt(useParams<{ id: string }>().id)
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const purchases: PurchasesState = useSelector((state: RootState) => state.shop)
    const userReducer: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(ApplicationActions.readVersions({ arguments: { application_id: appId } }))
        dispatch(ApplicationActions.readReleaseTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { id: appId } }))
        dispatch(ApplicationActions.readPackages({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ organization_id : userReducer.current.organization_id }))
        dispatch(PurchaseActions.readCart({}))
    }, [dispatch, appId])

    if (appsReducer.application.readStatus !== "fulfilled" ||
        appsReducer.version.readStatus !== "fulfilled" ||
        appsReducer.dlc.readStatus !== "fulfilled" ||
        appsReducer.dlc_types.readStatus !== "fulfilled" ||
        appsReducer.version_types.readStatus !== "fulfilled" ||
        appsReducer.packages.readStatus !== 'fulfilled' ||
        purchases.readStatus !== "fulfilled" ||
        purchases.cart.readStatus !== "fulfilled") return <Loading isOpen={true} message="Loading application data... Please wait." />

    const selectedApplication: Applications = appsReducer.application.applications[appId]
    const versions: Array<ApplicationVersions> = appsReducer.version.versions.filter(v => v !== null && v !== undefined && v.application_id === appId)
    const dlcs: Array<DLC> = appsReducer.dlc.dlcs.filter(v => v !== null && v !== undefined && [3, 17, 20].includes(v.id) === false && v.application_id === appId)
    const dlc_types: Array<DlcTypes> = appsReducer.dlc_types.types
    const version_types: Array<VersionTypes> = appsReducer.version_types.types
    const packages:Array<ApplicationPackages> = appsReducer.packages.packages.filter(p => p !== null && p !== undefined && p.application_id === appId)


    let user_owns_application = userOwnsProduct(purchases.purchases, userReducer.current, "application", appId)
    let product_in_cart = purchases.cart.carts.find(item => item !== null && item !== undefined && item.application_id === selectedApplication.id && item.user_id === userReducer.current.id)
    const handleCart = e => {
        if (product_in_cart === undefined) {
            const data = new FormData()
            data.append("user_id", userReducer.current.id.toString())
            data.append("application_id", selectedApplication.id.toString())
            dispatch(PurchaseActions.createCart({ "cart": data }))
        } else {
            dispatch(PurchaseActions.deleteCart({ arguments: { id: product_in_cart.id } }))
        }
    }

    return <ApplicationScreenJSX
        current={userReducer.current}
        price={selectedApplication.price}
        cover_path={selectedApplication.cover_path}
        description={selectedApplication.description}
        id={selectedApplication.id}
        logo_path={selectedApplication.logo_path}
        name={selectedApplication.name}
        primary_color={selectedApplication.primary_color}
        supported_platforms={selectedApplication.supported_platforms}
        versions={versions}
        dlcs={dlcs}
        dlc_types={dlc_types}
        version_types={version_types}
        product_owned={user_owns_application}
        product_in_cart={product_in_cart}
        packages={packages}
        handleCart={handleCart}
    />
}

export default ApplicationScreen