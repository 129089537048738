import { TbLogout } from "react-icons/tb"
import { BsJustify} from "react-icons/bs"
import { NavLink } from "react-router-dom"
import "./Nav.css"
import { STATIC_URL } from "../../Common/constants"
import Translate from "../Translate"
import LanguageSelector from "./LanguageSelector"

const NavLinkContainer = ({
    types_condition,
    location,
    route,
    icon,
    name,
    setShow,
    user,
    sub_routes,
    selected,
    setSelected
}) => types_condition.includes(user.type_id) ? route === undefined ? <div className="relative px-6 py-1 mt-1" key={route}>
    <div className="flex flex-col">
        <div
            onClick={() => {
                if (selected === name.props.label) setSelected(null)
                else setSelected(name.props.label)
            }}
            className="inline-flex items-center w-full text-md font-semibold transition-colors duration-150 hover:text-cythero-primary text-gray-200 cursor-pointer"
            aria-current="page"

        >{location.pathname.includes(sub_routes[0]?.route?.split("/")[1]) ? <span className="absolute inset-y-0 left-0 w-1 bg-cythero-primary rounded-tr-lg rounded-br-lg" aria-hidden="true">
        </span> : null} {icon} <span className="ml-4">{name}</span></div>
        <div className={selected === name.props.label ? "nav-dropdown selected w-full mt-2 ml-2 pl-6 border-l border-solid border-cythero-primary flex flex-col" : "nav-dropdown w-full ml-2 pl-6 border-l border-solid border-cythero-primary flex flex-col overflow-hidden"}>
            {sub_routes.map(r => <NavLink key={r.name} onClick={e => setShow(false)} className="text-slate-300 font-semibold my-1" to={r.route}>{r.name}</NavLink>)}
        </div>
    </div>
</div> : (
    <li className="relative px-6 py-1 mt-1" key={route}>
        <NavLink
            onClick={() => setShow(false)}
            className="inline-flex items-center w-full text-md font-semibold transition-colors duration-150 hover:text-cythero-primary text-gray-200"
            to={route === "/organization" ?
                user.type_id === 4 ? route + "/browse" :
                    user.organization_id === null ? route + "/new" : route + "/" + user.organization_id
                : route}
            aria-current="page"
        >
            {location.pathname.includes(route.split("/")[1]) ? <span className="absolute inset-y-0 left-0 w-1 bg-cythero-primary rounded-tr-lg rounded-br-lg" aria-hidden="true">
            </span> : null}
            {icon}
            <span className="ml-4">{name}</span>
        </NavLink>
    </li>
) : null

const UserSettings = ({ user, isMobile, setShow, logo }) => {
    let classes = isMobile === true ? "flex lg:hidden flex-col justify-between items-center pb-3 px-6 w-full mb-12" : "flex flex-col justify-between items-center h-full px-6 space-y-32 hidden lg:block"

    return (<div className={classes}>
        <div to="/user/profile/info" className="flex justify-between items-center w-full gap-2">
            <div className="flex justify-center items-center space-x-2">
                <div>
                    <img className="rounded-full w-8" src={logo !== null ? STATIC_URL + logo : "https://i.ibb.co/L1LQtBm/Ellipse-1.png"} alt="avatar" />
                </div>
                <div className="flex justify-start flex-col items-start">
                    <p className="cursor-pointer text-sm leading-5 text-white">{user.username}</p>
                    <p className="cursor-pointer text-xs leading-3 text-gray-300">{user.email}</p>
                </div>
            </div>
        </div>
    </div>)
}

const Nav = ({
    routes,
    show,
    setShow,
    location,
    logout,
    user,
    logo,
    selected,
    setSelected,
    children
}) => (
    <div>
        <div className="bg-gray-800 flex justify-between w-full p-2 items-center shadow-bottom">
            <div className="flex justify-center  items-center space-x-3 w-48">
                <img alt="Cythero Logo" width={40} height={40} src="/assets/images/logo.png" className="cursor-pointer" />
                <p className="text-2xl leading-6 text-white"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary animate-pulse">VR</sup></p>
            </div>
            <span className="flex">
                <UserSettings user={user} isMobile={false} setShow={setShow} logo={logo} />
                <div aria-label="toggler" className="flex justify-center items-center block lg:hidden">
                    <button aria-label="open" id="open" onClick={() => setShow(!show)} className={`focus:outline-none`}>
                        <BsJustify className="text-white text-xl" />
                    </button>
                </div>
            </span>
        </div>
        <span className="flex bg-gray-100">
            <div id="Nav" className={`${show ? '' : 'hide'} transform  xl:translate-x-0  ease-in-out transition duration-500 flex justify-start items-start h-[calc(100vh-56px)]  w-full lg:w-64 bg-gray-800 flex-col`}>
                <div className="mt-4 pb-5 flex flex-col justify-between w-full h-full">

                    <ul>
                        {routes.map((r, idx) => <NavLinkContainer
                            types_condition={r.types_condition}
                            key={r.name + "_" + idx}
                            location={location}
                            route={r.pathName}
                            name={r.name}
                            icon={r.icon}
                            user={user}
                            setShow={setShow}
                            sub_routes={r.sub_routes}
                            selected={selected}
                            setSelected={setSelected}
                        />)}
                        <li className="relative px-6 py-2">
                            <span onClick={() => logout()} className="cursor-pointer inline-flex items-center w-full text-lg font-semibold transition-colors duration-150 hover:text-cythero-primary text-gray-200" aria-current="page">
                                {location.pathname === "" ? <span className="absolute inset-y-0 left-0 w-1 bg-cythero-primary rounded-tr-lg rounded-br-lg" aria-hidden="true">
                                </span> : null}
                                <TbLogout />
                                <span className="ml-4"><Translate label="Logout" /></span>
                            </span>
                        </li>
                    </ul>

                    <UserSettings user={user} isMobile={true} setShow={setShow} logo={logo} />
                    <div className="absolute bottom-[10px] left-[11px] w-[calc(100%-20px)] p-3 bg-slate-600 rounded-lg">
                        <LanguageSelector />
                    </div>
                </div>
            </div>
            <div id="Main" className={`${show ? 'hide' : ''} lg:w-full`}>
                {children}
            </div>
        </span>
    </div>
)

export default Nav